import React from 'react'

const Faq = () => {
  return (
    <div className='terms-container'>
      <div className="header">
        FAQ
      </div>
      <div className="list">
        <ol>
            <li style={{color: "black", fontSize: "18px"}}>
            How do I return an item?
            <ul>
                <li style={{listStyle: "none"}}>
                FAQ sections usually consist of a series of categories and questions with concise answers across one or a few pages. A knowledge base is usually an extensive directory with comprehensive articles accessed by a search facility.
                </li>
            </ul>
            </li>
        </ol>
      </div>

    </div>
  )
}

export default Faq
