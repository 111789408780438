import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TbSearch } from "react-icons/tb";
import { CgShoppingCart } from "react-icons/cg";
import { AiOutlineHeart } from "react-icons/ai";
import { MdMenuOpen } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import "./Header.scss";
import Search from "./Search/Search";
import { Context } from "../../utils/context";
import Cart from "../Cart/Cart";
import Fav from "../Wishlist/Fav";
import Menu from "./Menu/Menu";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { cartCount, showCart, setShowCart, showFav, setShowFav, showMenu, setShowMenu } =
    useContext(Context);

  useEffect(() => {
    // Fetch categories from API
    const fetchCategories = async () => {
      try {
        const response = await fetch("/api/product-categories");
        if (response.ok) {
          const data = await response.json();
          setCategories(data.categories);
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <header className={`header-1 ${scrolled ? "sticky-header" : ""}`}>
        <div className="header-content">
          <div className="left" onClick={() => navigate("/")}>
            <img src="/logo.png" alt="Logo" style={{ width: "150px" }} />
          </div>
          <div className="right"></div>
          <div className="right">
            <TbSearch onClick={() => setSearchModal(true)} />
            <span className="fav-icon" onClick={() => setShowFav(true)}>
              <AiOutlineHeart />
            </span>
            <span className="cart-icon" onClick={() => navigate("/login")}>
              <FiUser />
            </span>
          </div>
        </div>
      </header>
      <header className={`main-header ${scrolled ? "sticky-header" : ""}`}>
        <div className="header-content">
          <ul className="left">
            <li onClick={() => navigate("/")}>Home</li>
            <li onClick={() => navigate("/about")}>About Us</li>
            <li onClick={() => navigate("/all-products")}>Products</li>
            <li className="dropdown">
              Categories
              <ul className="dropdown-content">
                {categories.map((category) => (
                  <li key={category.id}>{category.Name}</li>
                ))}
              </ul>
            </li>
            <li onClick={() => navigate("/contact")}>Contact Us</li>
            <li onClick={() => navigate("/faq")}>FAQ</li>
          </ul>
          {windowWidth <= 700 && (
            <div className="nav-left">
              <span className="menu-icon" onClick={() => setShowMenu(true)}>
                <MdMenuOpen />
              </span>
            </div>
          )}
          <div className="right">
            <span className="cart-icon" onClick={() => setShowCart(true)}>
              <CgShoppingCart />
              {!!cartCount && <span>{cartCount}</span>}
            </span>
          </div>
        </div>
      </header>
      {searchModal && <Search setSearchModal={setSearchModal} />}
      {showFav && <Fav />}
      {showCart && <Cart />}
      {showMenu && <Menu />}
    </>
  );
};

export default Header;
