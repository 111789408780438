import React from 'react';
import "./OrderDetails.scss"; // Import your SCSS file for styling

const OrderDetails = () => {
  return (
    <div className="order-details">
      <h2>Order Details</h2>

      <div className="section">
        <h3>Products List:</h3>
        <ul>
          {/* Iterate over your products list and display each product */}
          <li>Product 1</li>
          <li>Product 2</li>
          {/* Add more list items as needed */}
        </ul>
      </div>

      <div className="section">
        <h3>Payment Type:</h3>
        <p>Credit Card</p> {/* Example payment type, replace with actual payment type */}
      </div>

      <div className="section">
        <h3>Shipping Address:</h3>
        <p>123 Street Name, City, Country</p> {/* Example shipping address, replace with actual address */}
      </div>
    </div>
  );
}

export default OrderDetails;
