import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "./Shipping.scss"

const Shipping = () => {
  const navigate = useNavigate();
  const [newAddress, setNewAddress] = useState();
  return (
    <div>
      <div className="shipping-info">
        <div className="products">
          Products:
          
        </div>
        <div className="details">
          <div className="phone">
            <span>Phone: 8220316898</span>
            
          </div>
          <div className="address">
            <span>Address: 17, abc, abc</span>

          </div>
          <div className="new-address">
            <span>New Address?  <span style={{color: "blue", textDecoration: "underline", cursor: "pointer"}} onClick={() => navigate("/addNewAddress")}>Add Here!</span></span>
            </div>
        </div>
        <div className="amount">

        </div>
      </div>
    </div>
  )
}

export default Shipping
