// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

async function fetchProductNames(language) {
  try {
    const response = await fetch(`product-masters?language=${language}`);
    const data = await response.json();

    // Extract product names based on language
    const productNames = {};

    data.forEach(product => {
      if (language === "en") {
        productNames[product.skuid] = product.Name;
      } else if (language === "tn" && product.tamil) {
        productNames[product.skuid] = product.tamil;
      }
    });

    return productNames;
  } catch (error) {
    console.error("Error fetching product names:", error);
    return {};
  }
}

async function setupI18n() {
  const language = localStorage.getItem("i18nextLng") || navigator.language || "en";

  const productNames = await fetchProductNames(language);

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: {
            home: "Home",
            ...productNames, // Include product names for English
          },
        },
        tn: {
          translation: {
            home: "முகப்பு",
            ...productNames, // Include product names for Tamil
          },
        },
      },
      fallbackLng: "en",
      detection: {
        order: ["localStorage", "navigator"],
        caches: ["localStorage"],
      },
      interpolation: {
        escapeValue: false,
      },
    });
}

setupI18n();

export default i18n;
