import React, { useContext, useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";
import { fetchDataFromApi } from "../../utils/api";
import "./ResponsiveAllProduct.scss"

const ResponsiveAllProduct = () => {
  const {
    setShowFilter,
    categories,
    setCategories,
    products,
    setProducts,
    brands,
    setBrands,
  } = useContext(Context);
  const navigate = useNavigate();
  const handleMenuItemClick = () => {
    setShowFilter(false);
  };
  const [selectedAscending, setSelectedAscending] = useState(null);
  const [selectedDescending, setSelectedDescending] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    getCategories();
    getProducts();
    getBrands();
  }, [selectedBrand, selectedCategory, selectedAscending, selectedDescending]);

  const getCategories = () => {
    fetchDataFromApi("/api/product-categories?populate=*").then((res) => {
      setCategories(res);
    });
  };

  const getBrands = () => {
    fetchDataFromApi("/api/brands?populate=*").then((res) => {
      setBrands(res);
    });
  };

  const getProducts = () => {
    let apiUrl = "/api/product-masters?populate=*";

    // Apply brand filter
    if (selectedBrand) {
      apiUrl += `&filters[brand][$eq]=${selectedBrand}`;
    }

    // Apply category filter
    if (selectedCategory) {
      apiUrl += `&filters[category][$eq]=${selectedCategory}`;
    }

    // Apply ascending price filter
    if (selectedAscending !== null) {
      apiUrl += `&sort=mrp`;
    }

    // Apply descending price filter
    if (selectedDescending !== null) {
      apiUrl += `&sort=-mrp`;
    }

    fetchDataFromApi(apiUrl).then((res) => {
      setProducts(res);
      console.log("filter product:", res);
    });
  };

  const selectBrand = (brandName) => {
    setSelectedBrand(brandName);
    console.log("Brand check:", brandName);
  };

  const selectCategory = (categoryName) => {
    setSelectedCategory(categoryName);
    console.log("Category check", categoryName);
  };

  return (
    <div className="cart-panel">
      <div className="opac-layer" onClick={() => setShowFilter(false)}></div>
      <div className="cart-content">
        <div className="cart-header">
          <span className="close-btn" onClick={() => setShowFilter(false)}>
            <MdClose className="close-btn" />
          </span>
        </div>
        
          <div className="res-filter-option">
            <div className="res-filters">
              <p
                className={`res-filter-list ${
                  selectedAscending !== null ? "active-filter" : ""
                }`}
                onClick={() => {
                  if (selectedAscending !== null) {
                    setSelectedAscending(null);
                  } else {
                    setSelectedAscending(true);
                    setSelectedDescending(null); // Deselect descending if ascending is selected
                  }
                }}
              >
                Price (Low to High)
              </p>
              <p
                className={`res-filter-list ${
                  selectedDescending !== null ? "active-filter" : ""
                }`}
                onClick={() => {
                  if (selectedDescending !== null) {
                    setSelectedDescending(null);
                  } else {
                    setSelectedDescending(true);
                    setSelectedAscending(null); // Deselect ascending if descending is selected
                  }
                }}
              >
                Price (High to Low)
              </p>
            </div>
            <div className="res-category-header">
              <span>Brand</span>
              {/* <span className="empty-space"></span> */}
            </div>

            {brands?.data?.map((item) => (
              <div
                key={item.id}
                className={`res-category-list ${
                  selectedBrand === item.attributes.Name ? "active-filter" : ""
                }`}
                onClick={() => selectBrand(item.attributes.Name)}
              >
                {item.attributes.Name}
              </div>
            ))}
            <div className="res-category-header">
              <span>Category</span>
              {/* <span className="empty-space"></span> */}
            </div>

            {categories?.data?.map((item) => (
              <div
                key={item.id}
                className={`res-category-list ${
                  selectedCategory === item.attributes.Name
                    ? "active-filter"
                    : ""
                }`}
                onClick={() => selectCategory(item.attributes.Name)}
              >
                {item.attributes.Name}
              </div>
            ))}
          </div>
        
      </div>
    </div>
  );
};

export default ResponsiveAllProduct;
