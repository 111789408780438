import React from 'react'
import Stepper from './Stepper/Stepper'
import Shipping from './Shipping/Shipping'
import Payment from './Payment/Payment'
import OrderDetails from './OrderDetails/OrderDetails'

const STEPS = [
    
    {
        name: "Shipping Info",
        component: () => <div><Shipping /></div>
    },
    {
        name: "Payment Details",
        component: () => <div><Payment /></div>
    },
    {
        name: "Order Placed",
        component: () => <div><OrderDetails /></div>
    }
]

const Checkout = () => {
  return (
    <div>
      
      <Stepper stepperConfig={STEPS} />
    </div>
  )
}

export default Checkout
