import React, { useEffect, useContext } from "react";
import "./Home.scss";
import Banner from "./Banner/Banner";
import Category from "./Category/Category";
import Products from "../Products/Products";
import { fetchDataFromApi } from "../../utils/api";
import { Context } from "../../utils/context";
import HotProducts from "../Products/HotProducts/HotProducts"; // Correct import
import Trendings from  "../Products/Trending/Trendings";
import Populars from  "../Products/Popular/Populars";
import Deals from  "../Products/Deals/Deals";


const Home = () => {
    const { products, setProducts, categories, setCategories, hotProducts, setHotProducts, trendings, setTrendings, populars, setPopulars, deals, setDeals } =
        useContext(Context);
    useEffect(() => {
        getProducts();
        getCategories();
        getTrendings();
        getPopulars();
        getDeals();
    }, []);

    const getProducts = () => {
        fetchDataFromApi("/api/product-masters?populate=*").then((res) => {
            setProducts(res);
        });
      }
    

    

    const getHotProducts = () => {
        fetchDataFromApi(`/api/product-masters?populate=*&filters[type][$eq]=hot`).then((res) => {
            // Remove duplicates from the response data
            const uniqueProducts = Array.from(new Set(res.data.map(item => item.id)))
                .map(id => {
                    return res.data.find(item => item.id === id);
                });
            setHotProducts({ data: uniqueProducts });
            console.log("hot products:", uniqueProducts);
        });
    };

    const getTrendings = () => {
        fetchDataFromApi(`/api/product-masters?populate=*&filters[type][$eq]=trending`).then((res) => {
            // Remove duplicates from the response data
            const uniqueProducts = Array.from(new Set(res.data.map(item => item.id)))
                .map(id => {
                    return res.data.find(item => item.id === id);
                });
            setTrendings({ data: uniqueProducts });
            console.log("Trending products:", uniqueProducts);
        });
    };

    const getPopulars = () => {
        fetchDataFromApi(`/api/product-masters?populate=*&filters[type][$eq]=popular`).then((res) => {
            // Remove duplicates from the response data
            const uniqueProducts = Array.from(new Set(res.data.map(item => item.id)))
                .map(id => {
                    return res.data.find(item => item.id === id);
                });
            setPopulars({ data: uniqueProducts });
            console.log("Popular products:", uniqueProducts);
        });
    };

    const getDeals = () => {
        fetchDataFromApi(`/api/product-masters?populate=*&filters[type][$eq]=deals`).then((res) => {
            // Remove duplicates from the response data
            const uniqueProducts = Array.from(new Set(res.data.map(item => item.id)))
                .map(id => {
                    return res.data.find(item => item.id === id);
                });
            setDeals({ data: uniqueProducts });
            console.log("Deals products:", uniqueProducts);
        });
    };
    
    
    const getCategories = () => {
        fetchDataFromApi("/api/product-categories?populate=*").then((res) => {
            setCategories(res);
        });
    };

    return (
        <div>
            <Banner />
            <div className="main-content">
                <div className="layout">
                    <Category categories={categories} />
                    <Trendings
                        headingText="Trending Products"
                        trendings={trendings}
                    />
                    <HotProducts
                        headingText="Hot Products"
                        hotProducts={hotProducts}
                    />
                    <Populars
                        headingText="Popular Products"
                        populars={populars}
                    />
                    <Products
                        headingText="Products"
                        products={products}
                    />
                    <Deals
                        headingText="Deals"
                        deals={deals}
                    />
                     
                </div>
            </div>
        </div>
    );
};

export default Home;