import React, { useContext } from "react";
import { Context } from "../../../utils/context";
import { MdClose } from "react-icons/md";

const FavItem = () => {
    const { favItems, handleRemoveFromFav } = useContext(Context);
    console.log("favItems in FavItem component:", favItems);

    return (
        <div className="cart-products">
            {favItems.map((item) => (
                <div className="search-result-item" key={item.skuid} onClick={() => {}}>
                    <div className="image-container">
                        <img src={item.photo} alt="Product Thumbnail" />
                    </div>
                    <div className="prod-details">
                        <span className="name">{item.Name}</span>
                        <span className="name">{item.mrp}</span>
                        
                        <MdClose className="close-btn" onClick={() => handleRemoveFromFav(item)} />
                    </div>
                </div>
            ))}
        </div>
    );
};


export default FavItem;
