import React, { useContext } from "react";
import { MdClose } from "react-icons/md";
import { RiHandHeartLine } from "react-icons/ri";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";
import "./Menu.scss"

const Menu = () => {
    const { setShowMenu } = useContext(Context);
    const navigate = useNavigate();
    const handleMenuItemClick = () => {
        setShowMenu(false);
    };

    return (
        <div className="cart-panel">
            <div className="opac-layer" onClick={() => setShowMenu(false)}></div>
            <div className="cart-content">
                <div className="cart-header">
                    <span className="heading">
                    <div className="left" onClick={() => navigate("/")}>
            <img src="/logo.png" alt="Logo" style={{ width: "150px" }} />
          </div>
                    </span>
                    <span className="close-btn" onClick={() => setShowMenu(false)}>
                        <MdClose className="close-btn" />
                    </span>
                </div>
                <div className="mobile-nav">
                <ul className="nav-list">
            <li onClick={() => {navigate("/"); handleMenuItemClick();} }>Home</li>
            <li onClick={() => {navigate("/about"); handleMenuItemClick();}}>About Us</li>
            {/* <li onClick={() => navigate("/login")}>Login</li> */}
            <li onClick={() => {navigate("/all-products"); handleMenuItemClick();}}>Products</li>
            
            <li onClick={() => {navigate("/contact"); handleMenuItemClick();}}>Contact Us</li>
            <li onClick={() => {navigate("/faq"); handleMenuItemClick();}}>FAQ</li>
          </ul>
                </div>
            </div>
        </div>
    );
};

export default Menu;
