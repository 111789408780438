import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export const apiUrl = () => {
    const url = 'https://api.dharaniherbbals.com/api';
    return url;
}
export const storeUser = (data) => {
    const userInfo = data.user;
    localStorage.setItem('user', JSON.stringify({
        username: userInfo.username,
        jwt: data.jwt,
        email: userInfo.email,
        position: userInfo.position
    }))
}
export const userData = () => {
    const userDet = localStorage.getItem('user') || '""';
    return JSON.parse(userDet || {})
}
export const Protector = ({ Component }) => {
    const navigate = useNavigate();
    const { jwt } = userData()
    useEffect(() => {
        if (!jwt) {
            navigate('/login')
        }
    }, [navigate, jwt])
    // return <Component />;
}