import React, { useContext, useEffect, useState } from "react";
import "./AllProduct.scss";
import { fetchDataFromApi } from "../../utils/api";
import { Context } from "../../utils/context";
import Products from "./Products";
import { CiFilter } from "react-icons/ci";
import ResponsiveAllProduct from "./ResponsiveAllProduct";

const AllProduct = () => {
  const {
    categories,
    setCategories,
    products,
    setProducts,
    brands,
    setBrands,
    showFilter,
    setShowFilter
  } = useContext(Context);
  const [selectedAscending, setSelectedAscending] = useState(null);
  const [selectedDescending, setSelectedDescending] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    getCategories();
    getProducts();
    getBrands();
  }, [selectedBrand, selectedCategory, selectedAscending, selectedDescending]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCategories = () => {
    fetchDataFromApi("/api/product-categories?populate=*").then((res) => {
      setCategories(res);
    });
  };

  const getBrands = () => {
    fetchDataFromApi("/api/brands?populate=*").then((res) => {
      setBrands(res);
    });
  };

  const getProducts = () => {
    let apiUrl = "/api/product-masters?populate=*";

    // Apply brand filter
    if (selectedBrand) {
      apiUrl += `&filters[brand][$eq]=${selectedBrand}`;
    }

    // Apply category filter
    if (selectedCategory) {
      apiUrl += `&filters[category][$eq]=${selectedCategory}`;
    }

    // Apply ascending price filter
    if (selectedAscending !== null) {
      apiUrl += `&sort=mrp`;
    }

    // Apply descending price filter
    if (selectedDescending !== null) {
      apiUrl += `&sort=-mrp`;
    }

    fetchDataFromApi(apiUrl).then((res) => {
      setProducts(res);
      console.log("filter product:", res);
    });
  };

  const selectBrand = (brandName) => {
    setSelectedBrand(brandName);
    console.log("Brand check:", brandName);
  };

  const selectCategory = (categoryName) => {
    setSelectedCategory(categoryName);
    console.log("Category check", categoryName);
  };

  return (
    <div className="all-product-container">
      {windowWidth > 700 && (
        <div className="filter-option">
          <div className="filters">
            <p
              className={`filter-list ${
                selectedAscending !== null ? "active-filter" : ""
              }`}
              onClick={() => {
                if (selectedAscending !== null) {
                  setSelectedAscending(null);
                } else {
                  setSelectedAscending(true);
                  setSelectedDescending(null); // Deselect descending if ascending is selected
                }
              }}
            >
              Price (Low to High)
            </p>
            <p
              className={`filter-list ${
                selectedDescending !== null ? "active-filter" : ""
              }`}
              onClick={() => {
                if (selectedDescending !== null) {
                  setSelectedDescending(null);
                } else {
                  setSelectedDescending(true);
                  setSelectedAscending(null); // Deselect ascending if descending is selected
                }
              }}
            >
              Price (High to Low)
            </p>
          </div>
          <div className="category-header">
            <span>Brand</span>
            <span className="empty-space"></span>
          </div>

          {brands?.data?.map((item) => (
            <div
              key={item.id}
              className={`category-list ${
                selectedBrand === item.attributes.Name ? "active-filter" : ""
              }`}
              onClick={() => selectBrand(item.attributes.Name)}
            >
              {item.attributes.Name}
            </div>
          ))}
          <div className="category-header">
            <span>Category</span>
            <span className="empty-space"></span>
          </div>

          {categories?.data?.map((item) => (
            <div
              key={item.id}
              className={`category-list ${
                selectedCategory === item.attributes.Name
                  ? "active-filter"
                  : ""
              }`}
              onClick={() => selectCategory(item.attributes.Name)}
            >
              {item.attributes.Name}
            </div>
          ))}
        </div>
      )}
      {windowWidth <= 700 && (
        <div className="filter-icon">
          <span className="menu-icon" onClick={() => setShowFilter(true)}>
             Filters 
          </span>
        </div>
      )}
      <div className="product-list">
        <Products headingText="Products" products={products} />
      </div>
      {showFilter && <ResponsiveAllProduct />}
    </div>
  );
};

export default AllProduct;
