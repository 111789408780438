import React, { useState } from 'react';
import "./Payment.scss"

const Payment = () => {
  const [showDebitCard, setShowDebitCard] = useState(true); // State to control whether to show debit or credit card details

  return (
    <div className="payment">
      <div className="payment-form">
        <h2>Payment Details</h2>
        <div className="form-group">
          <label htmlFor="upi">UPI ID:</label>
          <input type="text" id="upi" name="upi" placeholder="Enter UPI ID" />
        </div>

        {/* Button to toggle between showing debit and credit card details */}
        <button className="btn-toggle" onClick={() => setShowDebitCard(!showDebitCard)}>
          {showDebitCard ? 'Credit Card' : 'Debit Card'}
        </button>

        {/* Conditional rendering based on the showDebitCard state */}
        {showDebitCard ? (
          <div className="form-group">
            <label htmlFor="debit-card">Debit Card Number:</label>
            <input type="text" id="debit-card" name="debit-card" placeholder="Enter Debit Card Number" />
            
            <label htmlFor="debit-expiration">Expiration Date:</label>
            <div className="expiration-date">
              <input type="text" id="debit-expiration-month" name="debit-expiration-month" placeholder="MM" maxLength="2" style={{ width: "40px", marginRight: "5px" }} />
              <input type="text" id="debit-expiration-year" name="debit-expiration-year" placeholder="YYYY" maxLength="4" style={{ width: "60px" }} />
            </div>

            <label htmlFor="cvv">CVV:</label>
            <input type="text" id="cvv" name="cvv" placeholder="CVV" style={{ width: "60px" }} />
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor="credit-card">Credit Card Number:</label>
            <input type="text" id="credit-card" name="credit-card" placeholder="Enter Credit Card Number" />
          </div>
        )}

        <button className="btn-pay">Pay Now</button>
      </div>
    </div>
  );
}

export default Payment;
