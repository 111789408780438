import { useEffect, useState } from "react";
import { fetchDataFromApi } from "../utils/api";

const useFetch = (endpoint) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const makeApiCall = async () => {
            try {
                setLoading(true);
                const res = await fetchDataFromApi(endpoint);
                setData(res);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        makeApiCall();
    }, [endpoint]);

    return { data, loading, error };
};

export default useFetch;