import React, { useState, useContext } from 'react';
import './Login.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../utils/context';
import { login } from '../../utils/api';
import { storeUser } from '../../helper';
import { apiUrl } from '../../helper';

const initialUser = { password: "", identifier: "" }

const Login = () => {
  const navigate = useNavigate();
  // const [userCredentials, setUserCredentials] = useState({ identifier: '', password: '' });
  const [error, setError] = useState('');
  const [user, setUser] = useState(initialUser)

  const handleChange = ({ target }) => {
    const { name, value } = target
    setUser((currentUser) => ({
      ...currentUser, [name]: value
    }))
  };

  const handleSubmit = async () => {
    try {
      if (user.identifier && user.password) {
        const { data } = await axios.post(apiUrl() + `/auth/local`, user);
        if (data.jwt) {
          // Successfully logged in
          storeUser(data);
          console.log("Login success");
          setUser(initialUser);
          // Redirect to dashboard or any other page
          // navigate('/dashboard');
        } else {
          // Incorrect credentials or other error from the server
          setError("Invalid credentials. Please try again.");
        }
      } else {
        // Missing username or password
        setError("Please enter both your mobile number and password.");
      }
    } catch (error) {
      console.log("Login failed:", error);
      // Handle other errors, such as network issues
      setError("An unexpected error occurred. Please try again later.");
    }
  }

  return (
    <div className='login'>
      <div className='login-card'>
        <h1>Welcome Back!</h1>
        <form onSubmit={handleSubmit}>
          <input
            type='text'
            name='identifier'
            placeholder='Mobile number...'
            value={user.identifier}
            onChange={handleChange}
          />
          <input
            type='password'
            name='password'
            placeholder='Password...'
            value={user.password}
            onChange={handleChange}
          />
          {error && <p className='error'>{error}</p>}
          <button type='submit' className='login-button'>Login</button>
        </form>
        <p className='forgot' onClick={() => navigate("/forgot-password")}>forgot password?</p>
        <div className="help"></div>
        <p className="text">Or Login with</p>
        {/* Add social login buttons */}
        <div className="signup">
          <p>New user?  <span className='signup-btn' onClick={() => navigate('/signup')}>Sign up</span></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
