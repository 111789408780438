import React from 'react'

const User = () => {
  return (
    <div>
      User Details
    </div>
  )
}

export default User
