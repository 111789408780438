import { useEffect } from "react";
import { createContext, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios';

export const Context = createContext();

const AppContext = ({ children }) => {
  const [categories, setCategories] = useState();
  const [brands, setBrands] = useState();
  const [products, setProducts] = useState([]);
  const [hotProducts, setHotProducts] = useState();
  const [trendings, setTrendings] = useState();
  const [populars, setPopulars] = useState();
  const [deals, setDeals] = useState();
  const [showCart, setShowCart] = useState(false);
  const [showFav, setShowFav] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [favItems, setFavItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [user, setUser] = useState({identifier: "", password: ""});
  const [addresses, setAddresses] = useState([]);
  const [isWishlistClicked, setIsWishlistClicked] = useState(false);
  const location = useLocation();

  const updateUserCart = async (newCartItems) => {
    // Update user's cart items in the backend
    try {
      const response = await axios.put('/api/user/cart', { cartItems: newCartItems }); // Adjust this URL based on your API
      setCartItems(newCartItems);
    } catch (error) {
      console.error('Error updating user cart:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    let count = 0;
    cartItems?.map((item) => (count += item.attributes.quantity));
    setCartCount(count);

    let subTotal = 0;
    cartItems.map(
      (item) => (subTotal += item.attributes.mrp * item.attributes.quantity)
    );
    setCartSubTotal(subTotal);
  }, [cartItems]);

  const handleAddToCart = (product, quantity) => {
    let items = [...cartItems];
    let index = items?.findIndex((p) => p.id === product?.id);
    if (index !== -1) {
      items[index].attributes.quantity += quantity;
    } else {
      product.attributes.quantity = quantity;
      items = [...items, product];
    }
    setCartItems(items);
  };

  const handleAddToFav = (product) => {
    setFavItems((prevFavItems) => [...prevFavItems, product]);
  };

  const handleRemoveFromCart = (product) => {
    let items = [...cartItems];
    items = items?.filter((p) => p.id !== product?.id);
    setCartItems(items);
  };

  const handleRemoveFromFav = (productId) => {
    setFavItems((prevFavItems) =>
      prevFavItems.filter((item) => item.id !== productId)
    );
  };

  const handleCartProductQuantity = (type, product) => {
    let items = [...cartItems];
    let index = items?.findIndex((p) => p.id === product?.id);
    if (type === "inc") {
      items[index].attributes.quantity += 1;
    } else if (type === "dec") {
      if (items[index].attributes.quantity === 1) return;
      items[index].attributes.quantity -= 1;
    }
    setCartItems(items);
  };

  return (
    <Context.Provider
      value={{
        products,
        setProducts,
        hotProducts,
        setHotProducts,
        trendings,
        setTrendings,
        populars,
        setPopulars,
        deals,
        setDeals,
        categories,
        setCategories,
        brands,
        setBrands,
        cartItems,
        setCartItems,
        favItems,
        setFavItems,
        handleAddToFav,
        handleAddToCart,
        cartCount,
        handleRemoveFromCart,
        handleRemoveFromFav,
        showCart,
        setShowCart,
        showFav,
        setShowFav,
        showMenu,
        setShowMenu,
        showFilter,
        setShowFilter,
        handleCartProductQuantity,
        cartSubTotal,
        user,
        setUser,
        updateUserCart,
        isWishlistClicked,
        setIsWishlistClicked
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;