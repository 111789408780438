import React from 'react'
import { useNavigate } from 'react-router-dom'

const Return = () => {
    const navigate = useNavigate();
  return (
    <div className="terms-container">
        <div className="header">
        Return / Refund
        </div>
        <div className="sub-header">
        Under what conditions can I return/ replace my product?
        </div>
        <div className="list">
            <ol>
                <li>Raise a return/ replacement request within 7 days from the date of delivery, if you’ve received wrong or expired product(s).</li>
                <li>Please raise a request here with order and contact details - <span onClick={() => navigate("/contact-us")} style={{color: "blue", textDecoration: "underline", cursor: "pointer"}}>Contact Us.</span></li>
                <li>In case of damaged/ missing product(s), raise a return/ replacement request within 2 days from the date of delivery. </li>
                <li>Two working days required to review your return request.</li>
                <li>After reviewing your return request, we will send our courier partner to pick up the products delivered to you.</li>
                <li>In case our reverse pick up service is not available at your location, you will need to self-ship the product via any reliable courier partner. We will reimburse the courier charges, either in your Google pay or original payment mode Wallet.</li>
                <li>After your product(s) is received, we will verify it against the claim and initiate the replacement or refund accordingly. Please note that replacement will depend upon the stock availability.</li>
            </ol>
        </div>
        <div className="sub-header">
        Under what conditions return/ replacement requests will not be accepted
        </div>
        <div className="list">
            <ol>
                <li>Opened/ used/ altered products.</li>
                <li>Original packaging (mono cartons, labels, etc.) missing.</li>
                <li>The return/ replacement request is generated after 7 days from the date of delivery.</li>
                <li>The damaged/ missing product is reported after 2 days from the date of delivery.</li>
            </ol>
        </div>
        <div className="sub-header">
        How are returns processed?
        </div>
        <div className="list">
            <ol>
                <li>
                Once you request to return a product, a pick up is organised for the item. Our courier partners will come to pick up the item within 5-7 business days after your return request has been received. This item is then brought back to our warehouse where it is checked by our quality control team. Once the product passes the quality control, a refund is initiated.
                </li>
            </ol>
        </div>
        <div className="sub-header">
        Can I cancel my order
        </div>
        <div className="list">
            <ol>
                <li>
                Please call us on +91- 9842312001 (Mon - Fri - 9 am to 6 pm), and we will help you in cancelling the order.
                </li>
                <li>
                **Dharani Herbbals reserves the right to cancel any order without pre-confirming the customer at any time and may verify any order before shipping the same to the customer that may include having a verbal or written confirmation from the customer.
                </li>
                <li>Note - Orders can be only cancelled till the status is “Ready to Ship".</li>
            </ol>
        </div>
        <div className="sub-header">
        How will I receive the refund for my cancelled or returned product?
        </div>
        <div className="list">
            <ol>
                <li>
                In case of prepaid orders, money will be returned to the bank account/ credit/debit card or where the payment was made from within 7 business working days.

                </li>
            </ol>
        
        </div>
        <div className="sub-header">
        How long does it take to receive a refund for a cancelled order or returned product?
        </div>
        <div className="list">
            <ol>
                <li>
                We will process your refund within 7 business days incase of cancellation of an order. In case of returns, we will refund the money after the product has been received by our warehouse and post completion of quality check. Please note, this entire process takes 2 weeks after the return has been picked up.
                </li>
            </ol>
        
        </div>
        <div className="sub-header">
        Can I return part of my order?
        </div>
        <div className="list">
            <ol>
                <li>
                Yes. You can return any products that are eligible for returns within 7 days of delivery.
                </li>
            </ol>
        
        </div>
    </div>
  )
}

export default Return
