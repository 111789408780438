import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import "./HotProduct.scss";
import { Context } from "../../../../utils/context";

const HotProduct = ({ id, data }) => {
  const [quantity, setQuantity] = useState(1);
  const { favItems, handleAddToFav, handleRemoveFromFav } = useContext(Context);
    const isFavorited = favItems.some((item) => item.id === data.id);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const toggleFavorite = () => {
    if (isFavorited) {
        handleRemoveFromFav(data.id);
    } else {
        handleAddToFav(data);
    }
};



  const nameToDisplay =
    i18n.language === "tn" && data.tamil ? data.tamil : data.Name;


  return (
    <div className="product-card-wrap">
      <div
        className="thumbnail"
        onClick={() => navigate("/product/" + id)}
      >
        <img src={data.photo} alt="Product Thumbnail" />
      </div>

      <div
        className="prod-details"
        onClick={() => navigate("/product/" + id)}
      >
        <span className="name">{t(nameToDisplay)}</span>
        <span className="category">
          By <span style={{ color: "#3bb77e" }}>{data.brand}</span>
        </span>
      </div>

      <div className="cart-button">
        <span className="price">&#8377;{data.mrp}</span>

        <button
          className="add-to-cart-button"
          onClick={() => navigate("/product/" + id)}
        >
          <MdOutlineAddShoppingCart />
        </button>

        <div
                className="wishlist-button"
                onClick={toggleFavorite}
                style={{ color: isFavorited ? "red" : "#000" }}
            >
                {isFavorited ? <AiFillHeart /> : <AiOutlineHeart />}
            </div>
      </div>
    </div>
  );
};

export default HotProduct;
