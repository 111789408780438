import React from 'react'

const Shipping = () => {
  return (
    <div className="terms-container">
        <div className="header">
        Shipping
        </div>
        <div className="sub-header">
        General
        </div>
        <div className="list">
            <ol>
                <li>Dharani herbbals (“we” and “us”) is the operator of (<a href='https://www.dharaniherbbals.in' target='_blank'>https://www.dharaniherbbals.in</a>) (“Website”). By placing an order through this Website you will be agreeing to the terms below. These are provided to ensure both parties are aware of and agree upon this arrangement to mutually protect and set expectations on our service.</li>
                <li>Subject to stock availability. We try to maintain accurate stock counts on our website but from time-to-time there may be a stock discrepancy and we will not be able to fulfill all your items at time of purchase. In this instance, we will fulfill the available products to you, and contact you about whether you would prefer to await restocking of the backordered item or if you would prefer for us to process a refund.</li>
            </ol>
        </div>
        <div className="sub-header">
        Shipping Costs
        </div>
        <div className="list">
            <ol>
                <li>
                Shipping costs are calculated during checkout items in the order. Payment for shipping will be collected with the purchase.
                </li>
                <li>
                This price will be the final price for shipping cost to the customer.
                </li>
            </ol>
        </div>
        <div className="sub-header">
        Delivery Terms
        </div>
        <div className="list">
            <ol>
                <li>
                Transit Time Domestically : In general, domestic shipments are in transit for 2 – 7 days
                </li>
                <li>
                Transit time Internationally : Generally, orders shipped internationally are in transit for 4 – 22 days. This varies greatly depending on the courier you have selected. We are able to offer a more specific estimate when you are choosing your courier at checkout.
                </li>
                <li>
                Dispatch Time : Orders are usually dispatched within 2 business days of payment of order Our warehouse operates on Monday – Saturday during standard business hours, except on national holidays at which time the warehouse will be closed. In these instances, we take steps to ensure shipment delays will be kept to a minimum.
                </li>
                <li>
                Change Of Delivery Address : For change of delivery address requests, we are able to change the address at any time before the order has been dispatched.
                </li>
                <li>
                Items Out Of Stock : If an item is out of stock, we will wait for the item to be available before dispatching your order. Existing items in the order will be reserved while we await this item.
                </li>
                <li>
                Delivery Time Exceeded : If delivery time has exceeded the forecasted time, please contact us so that we can conduct an investigation.
                </li>
            </ol>
        </div>
        <div className="sub-header">
        Tracking Notifications
        </div>
        <div className="list">
            <ol>
                <li>
                Upon dispatch, customers will receive a tracking link from which they will be able to follow the progress of their shipment based on the latest updates made available by the shipping provider.
                </li>
            </ol>
        </div>
        <div className="sub-header">
        Parcels Damaged In Transit
        </div>
        <div className="list">
            <ol>
                <li>
                If you find a parcel is damaged in-transit, if possible, please reject the parcel from the courier and get in touch with our customer service. If the parcel has been delivered without you being present, please contact customer service with next steps.
                </li>
            </ol>
        </div>
        <div className="sub-header">
        Duties & Taxes
        </div>
        <div className="list">
            <ol>
                <li>Sales Tax : Sales tax has already been applied to the price of the goods as displayed on the website</li>
                <li>Import Duties & Taxes : Import duties and taxes for international shipments may be liable to be paidupon arrival in destination country. This varies by country, and Dharani herbbals encourage you to be aware of these potential costs before placing an order with us.</li>
                <li>If you refuse to to pay duties and taxes upon arrival at your destination country, the goods will be returned to Dharani herbbals at the customers expense, and the customer will receive a refund for the value of goods paid, minus the cost of the return shipping. The cost of the initial shipping will not be refunded.</li>
            </ol>
        </div>
        <div className="sub-header">
        Cancellations
        </div>
        <div className="list">
            <ol>
                <li>
                If you change your mind before you have received your order, we are able to accept cancellations at any time before the order has been dispatched. If an order has already been dispatched, please refer to our refund policy.
                </li>
            </ol>
        </div>
        <div className="sub-header">
        Insurance
        </div>
        <div className="list">
            <ol>
                <li>
                Parcels are insured for loss and damage up to the value as stated by the courier.
                </li>
                <li>
                Process for parcel damaged in-transit : We will process a refund or replacement as soon as the courier has completed their investigation into the claim.
                </li>
                <li>
                Process for parcel lost in-transit :We will process a refund or replacement as soon as the courier has conducted an investigation and deemed the parcel lost.
                </li>
            </ol>
        </div>
        <div className="sub-header">
        Customer service
        </div>
        <div className="list">
            <ol>
                <li>
                For all customer service enquiries, please email us at <a href='mailto:info@dharaniherbbals.in'>info@dharaniherbbals.in</a>
                </li>
            </ol>
        </div>
    </div>
  )
}

export default Shipping
