import axios from "axios";

//login credentials
const baseURL = process.env.REACT_APP_STRIPE_APP_DEV_URL; // Using the Strapi backend URL
const headers = {
    "Content-Type": "application/json",
};

const params = {
    headers: {
        Authorization: "bearer " + process.env.REACT_APP_STRIPE_DEV_APP_KEY,
    },
};
const instance = axios.create({
    baseURL,
    headers,
});

export const login = async (credentials) => {
    try {
        const response = await fetch('https://api.dharaniherbbals.com/api/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        if (!response.ok) {
            throw new Error('Failed to login');
        }

        const data = await response.json();
        localStorage.setItem('token', data.token); // Store the JWT token
        // Redirect to authenticated route
    } catch (error) {
        console.error('Error logging in:', error);
        // Handle login error
    }
};

const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        // Handle unauthenticated user
        return;
    }

    try {
        const response = await fetch('https://api.dharaniherbbals.com/api/users', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch user data');
        }

        const userData = await response.json();
        // Handle user data
    } catch (error) {
        console.error('Error fetching user data:', error);
        // Handle fetch error
    }
}

export const fetchDataFromApi = async (url) => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_STRIPE_APP_DEV_URL + url,
            params
        );
        return data;
    } catch (err) {
        console.log(err);
        return err;
    }
};

export const makePaymentRequest = axios.create({
    baseURL: process.env.REACT_APP_STRIPE_APP_DEV_URL,
    headers: {
        Authorization: "bearer " + process.env.REACT_APP_STRIPE_DEV_APP_KEY,
    },
});
