import React from "react";
import "./About.scss";

const About = () => {
  return (
    <div className="about">
      <div className="about-container">
        <div className="about-content">
          <img
            src="https://api.dharaniherbbals.com/uploads/about_54cd77d099.jpg"
            alt="Dharani Herbbals"
          />
          <p>
      <h1>Welcome to Dharani Herbbals</h1>
            Dharani Herbbals was formed in the year 2004 to do researches in
            Siddha & Ayurveda. As a result, Dharani herbals was founded in 2007.
            Ramcare Chemparuthi herbal hair oil, Ramcare Moodakattran herbal
            pain reliever oil, Ramcare Luxsna herbal skin oil- Brand Non
            patented medicines were developed.
            <br />
            <br />
            <br />
            Promote awareness for nature cure by introducing 100% Herbal
            Medicines. Herbal medicines are Natural and harmless with no side
            effects.Hence these medicines have warm welcome among people all
            over the world. We are doing more and more research in herbs. As we
            are living in polluted atmosphere, the only way to safe-guard our
            health is to turn back to nature cure.
          </p>
        </div>
        <div className="about-grid">
          <div className="grid-header">
            What We Provide?
            <span className="line"></span>
          </div>
          <div class="grid-container">
            <div class="grid-item">
              <img
                src="https://api.dharaniherbbals.com/uploads/icon_1_4f3fae7fbf.svg"
                alt="Best Price & Offers"
              />
              <span>Best Price & Offers</span>
            </div>
            <div class="grid-item">
              <img
                src="https://api.dharaniherbbals.com/uploads/icon_2_cf27a289b2.svg"
                alt="Wide Assortment"
              />
              <span>Wide Assortmen</span>
            </div>
            <div class="grid-item">
              <img
                src="https://api.dharaniherbbals.com/uploads/icon_3_564ebaec73.svg"
                alt="Fast Delivery"
              />
              <span>Fast Delivery</span>
            </div>
            <div class="grid-item">
              <img
                src="https://api.dharaniherbbals.com/uploads/icon_4_06af51dbc0.svg"
                alt="100% Secured"
              />
              <span>100% Secured</span>
            </div>
            <div class="grid-item">
              <img
                src="https://api.dharaniherbbals.com/uploads/icon_5_3eb3d6cdf1.svg"
                alt="100% Satisfaction"
              />
              <span>100% Satisfaction</span>
            </div>
            <div class="grid-item">
              <img
                src="https://api.dharaniherbbals.com/uploads/icon_6_1e7b8c3647.svg  "
                alt="Great Daily Deals"
              />
              <span>Great Daily Deals</span>
            </div>
          </div>
        </div>
        <div className="second-container">
            <img src="https://api.dharaniherbbals.com/uploads/about_5_713cd6a0fb.png" alt="Dharani Herbbals" />
            <div className="content">
            <h1>Our Performance</h1>
            <span>Your Partner for Herbal Health Care Products</span>
            <p>
            Ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
            <br />
            <br />
            Pitatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
            </p>
            </div>            
        </div>
        <div className="third-container">
            <div className="column">
                <span>Who We Are?</span>
                <p>
                Dharani Herbbals was formed in the year 2004 to do researches in Siddha & Ayurveda. As a result, Dharani herbals was founded in 2007. Ramcare Chemparuthi herbal hair oil, Ramcare Moodakattran herbal pain reliever oil, Ramcare Luxsna herbal skin oil- Brand Non patented medicines were developed.
                </p>
            </div>
            <div className="column">
                <span>Our History</span>
                <p>
                Promote awareness for nature cure by introducing 100% Herbal Medicines. Herbal medicines are Natural and harmless with no side effects.Hence these medicines have warm welcome among people all over the world. We are doing more and more research in herbs. As we are living in polluted atmosphere, the only way to safe-guard our health is to turn back to nature cure.
                </p>
            </div>
            <div className="column">
                <span>Our Mission</span>
                <p>
                Promote awareness for nature cure by introducing 100% Herbal Medicines. Herbal medicines are Natural and harmless with no side effects. As we are living in polluted atmosphere, the only way to safe-guard our health is to turn back to nature cure.
                </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default About;
