import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Category from "./components/Category/Category";
import SingleProduct from "./components/SingleProduct/SingleProduct";
import Newsletter from "./components/Footer/Newsletter/Newsletter";
import AppContext from "./utils/context";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import { useTranslation } from "react-i18next";
import Checkout from "./components/Checkout/Checkout";
import User from "./components/User/User";
import About from "./components/About/About";
import Terms from "./components/Terms/Terms";
import Privacy from "./components/Privacy/Privacy";
import Return from "./components/Return/Return";
import Shipping from "./components/Shipping/Shipping";
import Faq from "./components/FAQ/Faq";
import AllProduct from "./components/AllProduct/AllProduct"
import Contact from "./components/Contact/Contact"


function App() {

    const {t} = useTranslation();


    return (
        <BrowserRouter>
            <AppContext>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/category/:Name" element={<Category />} />
                    <Route path="/product/:id" element={<SingleProduct />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/user" element={<User />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/terms-and-conditions" element={<Terms />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/refund-returns" element={<Return />} />
                    <Route path="/shipping" element={<Shipping />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/all-products" element={<AllProduct />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
                {/* <Newsletter /> */}
                <Footer />
            </AppContext>
        </BrowserRouter>
    );
}

export default App;
