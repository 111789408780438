import React, { useState } from 'react';
import "./PhoneOtpForm.scss";
import OtpForm from './OtpForm/OtpForm';
import { useNavigate } from 'react-router-dom';

const PhoneOtpForm = () => {
    const navigate = useNavigate(); // Correct usage of useNavigate hook
    const [user, setUser] = useState({
        id: "",
        name: "",
        identifier: "",
        email: "",
        password: ""
    });
    const [otp, setOtp] = useState('');

    const [showOtpForm, setShowOtpForm] = useState(false);

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const regex = /[^0-9]/g;
    
        if (!user.identifier || user.identifier.length < 10 || regex.test(user.identifier)) {
            alert('Invalid Phone Number');
            return;
        }
    
        // Create a user object matching the expected format by the server
        const newUser = {
            username: user.name, // Assuming you want to use 'name' as 'username'
            email: user.email,
            identifier: user.identifier,
            password: user.password
        };
    
        // Save user data to the API endpoint
        try {
            const response = await fetch('https://api.dharaniherbbals.com/api/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newUser), // Sending the modified user object
            });
    
            if (!response.ok) {
                throw new Error('Failed to save user data');
            }
    
            // Generate and send OTP
            const generatedOtp = generateOTP();
            // Here you can send the OTP to the user's mobile number using your preferred method (SMS, email, etc.)
            console.log('Generated OTP:', generatedOtp);
            setOtp(generatedOtp);
    
            setShowOtpForm(true);
        } catch (error) {
            console.error('Error saving user data:', error);
            // Handle error saving user data
        }
    }

    const generateOTP = () => {
        // Generate a random 4-digit OTP
        return Math.floor(1000 + Math.random() * 9000).toString();
    }

    const onOtpSubmit = (enteredOtp) => {
        if (enteredOtp === otp) {
            console.log('OTP verification successful');
            window.alert('Signup Successful! Redirecting to Login Page');
            navigate('/login'); // Navigate to login page
        } else {
            console.log('Incorrect OTP');
            window.alert('Incorrect OTP. Please try again.');
        }
    }

    return (
        <div className="form-container">
            {!showOtpForm ? (
                <form onSubmit={handleSubmit} className="input-container">
                    <input
                        type='text'
                        placeholder='Enter your Name'
                        name="name"
                        value={user.name}
                        onChange={handleChange}
                    />
                    <input type="hidden" name="id" value={user.id} />
                    <input
                        type='text'
                        placeholder='Enter your phone number'
                        name="identifier"
                        value={user.identifier}
                        onChange={handleChange}
                    />
                    <input
                        type='text'
                        placeholder='Enter your Email'
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                    />
                    <input
                        type='password'
                        placeholder='Enter your password'
                        name="password"
                        value={user.password}
                        onChange={handleChange}
                    />
                    <button type="submit">Submit</button>
                </form>
            ) : (
                <div>
                    <p>OTP sent to mobile number <span style={{ color: 'green', textDecoration: 'underline', cursor: 'pointer' }}>{user.identifier}</span></p>
                    <OtpForm length={4} onOtpSubmit={onOtpSubmit} />
                </div>
            )}
        </div>
    );
}

export default PhoneOtpForm;
