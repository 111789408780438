import React, { useState, useEffect } from "react";
import "./Banner.scss";

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [
    "https://api.dharaniherbbals.com/uploads/slider2_5b2e770ca2.jpg",
    "https://api.dharaniherbbals.com/uploads/slider3_75fdb37975.jpg",
    "https://api.dharaniherbbals.com/uploads/slider4_1cd8020bd1.jpg" 
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1));
    }, 7000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="slider">
      <div className="container" style={{transform:`translateX(-${currentSlide * 100}vw)`}}>
        {data.map((image, index) => (
          <img key={index} src={image} alt="" />
        ))}
      </div>
    </div>
  );
};

export default Banner;
