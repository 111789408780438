import React from 'react'
import "./Signup.scss"
import PhoneOtpForm from '../PhoneOtpForm/PhoneOtpForm'

const Signup = () => {
  return (
    <div className='signup-page'>
        <h1>Welcome to Dharani Herbbals</h1>
        <h2>Create an account</h2>
      <PhoneOtpForm />
    </div>
  )
}

export default Signup
