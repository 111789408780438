import React, { useEffect, useRef, useState } from 'react';

const OtpForm = ({ length = 4, onOtpSubmit = () => {} }) => {
  const [otp, setOtp] = useState(new Array(length).fill(''));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    //validate for enter only numbers
    if (isNaN(value)) return;
   
    const newOtp = [...otp];
    //validation for only one number in an input
    newOtp[index] = value.substring(value.length-1);
    
    setOtp(newOtp);

    // Submit trigger
    const combineOtp = newOtp.join('');
    if (combineOtp.length === length) onOtpSubmit(combineOtp);

    // handle input focus when the value is set

    if (value && index < length - 1 && inputRefs.current[index + 1]){
      inputRefs.current[index+1].focus();
    }
    
  };

  const handleClick = (index) => {
    if(index > 0 && !otp[index-1]){
      inputRefs.current[index-1].focus();
    }

    
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0 && inputRefs.current[index - 1]){
      inputRefs.current[index-1].focus();
    }
   
  };

  return (
    <div>
      {otp.map((value, index) => (
        <input
          type='text'
          key={index}
          ref={(input) => {
            if (input) inputRefs.current[index] = input;
          }}
          value={value}
          onChange={(e) => handleChange(index, e)}
          onClick={() => handleClick(index)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          style={{ width: '40px', height: '40px', margin: '5px', textAlign: 'center', fontSize: '1.2em' }}
        />
      ))}
    </div>
  );
};

export default OtpForm;
