import React from "react";
import Product from "../Products/Product/Product"
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Products.scss";

const Products = ({ products, innerPage, headingText }) => {
  const { id } = useParams();
  
  return (
    <div className="products-container">
      {!innerPage && <div className="sec-heading">{headingText}</div>}
        <div className="allproducts">
        {products?.data?.map((item) => (
          
          <Product key={item.id} id={item.id} data={item.attributes}  />
        
      ))}

        </div>
        
    </div>
  );
};

export default Products;
