import React from "react";
import "./Footer.scss";
import { FaLocationArrow, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import Payment from "../../assets/payments.png";
import { useNavigate } from "react-router-dom";



const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="col">
                    <div className="title">About</div>
                    <div className="text">
                    Dharani herbbals was formed in the year 2004 to do researches in Siddha & Ayurveda. As a result, Dharani herbals was founded in 2007... 
                    <p className='forgot' onClick={() => navigate("/about")}>Read more</p>
                    </div>
                </div>
                <div className="col">
                    <div className="title">Contact</div>
                    <div className="c-item">
                        <FaLocationArrow />
                        <div className="text">
                        7/470-1,Chemparuthi Street, West Nehru Nagar, Punjai Puliampatti. Sathyamangalam(TALUK), Erode(DT), Tamil Nadu - 638 459, India.
                        </div>
                    </div>
                    <div className="c-item">
                        <FaMobileAlt />
                        <div className="text"><a href="tel:+919842312001" className="text">+91-9842312001</a></div>
                    </div>
                    <div className="c-item">
                        <FaEnvelope />
                        <div className="text"><a href="mailto:salesdharani@gmail.com" className="text">salesdharani@gmail.com</a></div>
                    </div>
                    <div className="c-item">
                        <FaEnvelope />
                        <div className="text"><a href="mailto:info@dharaniherbbals.in" className="text">info@dharaniherbbals.in</a></div>
                    </div>
                </div>
                <div className="col">
    <div className="title">Customer Service</div>
        <ul><a href="/terms-and-conditions" className="text">Terms & Conditions</a></ul>
        <ul><a href="/privacy-policy" className="text">Privacy Policy</a></ul>
        <ul><a href="/refund-returns" className="text">Refund/Returns</a></ul>
        <ul><a href="/shipping" className="text">Shipping</a></ul>
        <ul><a href="/faq" className="text">FAQ</a></ul>
</div>
<div className="col">
    <div className="title">Downloads</div>
    <ul>
        <li><a href="mini_brochure_updated_2022.pdf" className="text" download="mini_brochure_updated_2022.pdf">Download Brochure</a></li>
        <li><a href="All Product_list_new_proof.pdf" className="text" download="Product_list_new_proof.pdf">Download Order Sheet</a></li>
        <li><a href="/" className="text">Download Product Uses</a></li>
    </ul>
</div>

            </div>
            <div className="bottom-bar">
                <div className="bottom-bar-content">
                    <span className="text">
                        All Rights Reserved @ Dharani Herbbals | Developed By <a href="http://thinkaside.com" target="_blank" className="text"> ThinkAside </a>
                    </span>
                    {/* <img src={Payment} /> */}
                </div>
            </div>
        </div>
    );
};

export default Footer;
