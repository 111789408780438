import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import "./Product.scss";

const Product = ({ id, data, handleAddToFav, handleRemoveFromFav }) => {
  const [isFavorited, setIsFavorited] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsFavorited(data.isFavorited); // Set initial favorite state based on data
  }, [data.isFavorited]);

  const toggleFavorite = () => {
    if (isFavorited) {
      handleRemoveFromFav(data.id); // Pass productId to remove from fav
    } else {
      handleAddToFav(data); // Add to favorites
    }
    setIsFavorited(!isFavorited); // Toggle favorite state
  };

  const nameToDisplay =
    i18n.language === "tn" && data.tamil ? data.tamil : data.Name;

  return (
    <div className="product-card-wrap">
      <div className="thumbnail" onClick={() => navigate("/product/" + id)}>
        <img src={data.photo} alt="Product Thumbnail" />
      </div>

      <div className="prod-details" onClick={() => navigate("/product/" + id)}>
        <span className="name">{t(nameToDisplay)}</span>
        <span className="category">
          By <span style={{ color: "#3bb77e" }}>{data.brand}</span>
        </span>
      </div>

      <div className="cart-button">
        <span className="price">&#8377;{data.mrp}</span>

        <button className="add-to-cart-button" onClick={() => navigate("/product/" + id)}>
          <MdOutlineAddShoppingCart />
        </button>

        <span className="wishlist-button" onClick={toggleFavorite}>
          {isFavorited ? <AiFillHeart /> : <AiOutlineHeart />}
        </span>
      </div>
    </div>
  );
};

export default Product;
