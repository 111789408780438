import React from "react";
import "./Stepper.scss";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

const Stepper = ({ stepperConfig = [] }) => {
  const [stepper, setStepper] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0
  });
  const stepRef = useRef([])

  useEffect(() => {
    setMargins({
      marginLeft: stepRef.current[0].offsetWidth / 2,
      marginRight: stepRef.current[stepperConfig.length - 1].offsetWidth / 2,
    });
  }, [stepRef, stepperConfig.length]);

  if (!stepperConfig.length) {
    return <></>;
  }

  const handleStep = () => {
    setStepper((prevStep) => {
        if(prevStep === stepperConfig.length) {
            setIsComplete(true);
            return prevStep
        }
        else{
            return prevStep + 1;
        }
    })
  };

  const calculateProgressBarWidth = () => {
    return (
        (stepper - 1) / (stepperConfig.length - 1)
    ) * 100;
  }

  const ActiveComponent = stepperConfig[stepper - 1]?.component

  return (
    <>
      <div className="stepper">
        {stepperConfig.map((step, index) => {
          return (
            <div
              key={step.name}
              ref={(e) => (stepRef.current[index] = e)}
              className={`step ${
                stepper > index + 1 || isComplete ? "complete" : ""
              } ${stepper === index + 1 ? "active" : ""}`}
            >
              <div className="step-number">
                {stepper > index + 1 || isComplete ? (
                  <span>&#10003;</span>
                ) : (
                  index + 1
                )}
              </div>
              <div className="step-name">{step.name}</div>
            </div>
          );
        })}
        <div
        className="progress-bar"
        style={{
            width: `calc(100% - ${margins.marginLeft + margins.marginRight}px)`,
            marginLeft: margins.marginLeft,
            marginRight: margins.marginRight
          }}
        >
            <div
            className="progress"
            style={{width:`${calculateProgressBarWidth()}%`}}
            >

            </div>
        </div>
      </div>
      <div className="content">
      {ActiveComponent && <ActiveComponent />}
      {!isComplete && (
        <button className="btn button" onClick={handleStep}>
          {stepper === stepperConfig.length ? "Finish" : "Next"}
        </button>
      )}
      </div>
      
    </>
  );
};

export default Stepper;
