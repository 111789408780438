import React, { useContext } from "react";
import { MdClose } from "react-icons/md";
import { RiHandHeartLine } from "react-icons/ri";
import { Context } from "../../utils/context";
import FavItem from "./FavItem/FavItem";

const Fav = () => {
    const { favItems, setShowFav } = useContext(Context);

    return (
        <div className="cart-panel">
            <div className="opac-layer" onClick={() => setShowFav(false)}></div>
            <div className="cart-content">
                <div className="cart-header">
                    <span className="heading">Wishlist</span>
                    <span className="close-btn" onClick={() => setShowFav(false)}>
                        <MdClose className="close-btn" />
                        <span className="text">close</span>
                    </span>
                </div>

                {!favItems.length ? (
                    <div className="empty-cart">
                        <RiHandHeartLine />
                        <span>No products in the Wishlist.</span>
                        <button className="return-cta" onClick={() => {}}>
                            RETURN TO SHOP
                        </button>
                    </div>
                ) : (
                    <FavItem />
                )}
            </div>
        </div>
    );
};

export default Fav;
